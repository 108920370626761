import React from "react";
import { Link } from 'react-router-dom';
import Logo from '../components/assets/images/TLH-logo-small.png';

function Homelink(props) {
  return (
    <div className="text-center userful_link mt-5">   
    <div>                   
        <div className="logo_wrap"><img src={Logo} width={250} className ="tlh-logo" alt="logo" /></div>
        <div className="link_wrap">
            <Link to="/userguide" >User Guide</Link>
            <Link to="/apiinfo" >API Info</Link>
            <Link to={"https://customer-api.theloginhub.eu?client_id=3hg6aeibgasewu7z236hgsmj3k58zi&login_method=sms"} target="_blank">Demo</Link>
        </div>
        </div>
    </div>
  )
}

export default Homelink