import React from "react";
import FacebookLogo from "../assets/images/facebook.svg";
import GoogleLogo from "../assets/images/google.svg";


const SocialLogin = ({ onClick }) => (
    <div className="social_wrap pt-3">
        <p className="grey">Or verify with</p>
        <span onClick={() => onClick('facebook')}>
            <img src={FacebookLogo} width="40" alt="Facebook Logo" />
        </span>
        <span onClick={() => onClick('google')}>
            <img src={GoogleLogo} width="38" alt="Google Logo" />
        </span>
    </div>
);

export default SocialLogin;
