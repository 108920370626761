import React, { Component } from "react";
import Homelink from '../components/Nav';
import LoginForm  from '../components/LoginForm/LoginForm';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {        
            checkParam: false
        }
    }
    getQueryParams = () => {
        const queryString = window.location.search.slice(1);
        const params = {};
        queryString.split('&').forEach(param => {
            const [key, value] = param.split('=');
            if (key) {
                params[decodeURIComponent(key)] = decodeURIComponent(value || '');
            }
        });
        return params;
    };
    componentDidMount = async () => {
        const searchParams = this.getQueryParams();
        if (Object.entries(searchParams).length > 0) {
            this.setState({ checkParam: true });
        }
    } 
    render() {
        return (
            <div className='container'>
                {

                    (!this.state.checkParam) ?
                        <Homelink />
                        : null
                }
                {
                    (this.state.checkParam) ?
                       <LoginForm />
                        : null
                }

            </div>


        )
    }
}
export default HomePage;
