import React, { Component } from "react";
import { Link } from 'react-router-dom';
import SmsLogin from "./SmsLogin";
import EmailLogin from "./EmailLogin";
import SocialLogin from "./SocialLogin";
import loadingImg from '../assets/images/tlh-loading.png';
import CodeVerification from './CodeVerification';
import Homelink from '../../components/Nav';
import { api_url } from '../../constants/api_constant';
import { getParams, checkIsBase64, decodeBase64 } from '../../utils/Functions';


class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            userName: "",
            clientId: "",
            loginMethod: "",
            emailOption: "url",
            checkParam: false,
            error: false,
            hide: false,
            allhide: false,
            apikey: "",
            companyName: "",
            companyLogo: "",
            verifyCode: [],
            errorMessage: "",
            successMessage: "",
            clientState: "",
            loading: false,
            clickCount: 0,
            linkUrl: "",
            flag: "",
            apiCalled: false,
            codes: ['', '', '', ''],

        }
        this.inputRefs = Array.from({ length: 4 }, () => React.createRef());
    }

    onValueChange = (e) => {
        this.setState({ emailOption: e.target.value });
    }

    changeuserName = async (e) => {
        this.setState({ userName: e.target.value.split(' ').join('') });
    }

    handleInputChange = (index, e) => {
        const { value } = e.target;
        const newInputs = [...this.state.codes];
        newInputs[index] = value;
        this.setState({ codes: newInputs });
        // Focus on the next input field if available
        if (value !== '' && index < this.state.codes.length - 1 && this.inputRefs[index + 1].current) {
            this.inputRefs[index + 1].current.focus();
        }
    };

    handlePaste = (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData.getData('Text');
        const pastedValues = clipboardData.slice(0, this.state.codes.length).split(''); // Assuming only 'inputs.length' characters will be pasted
        const newInputs = [...this.state.codes];
        pastedValues.forEach((value, index) => {
            newInputs[index] = value;
            // Focus on the next input field if available
            if (index < this.state.codes.length - 1 && this.inputRefs[index + 1].current) {
                this.inputRefs[index + 1].current.focus();
            }
        });
        this.setState({ codes: newInputs });
    };

    handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && this.state.codes[index] === '') {
            // If backspace is pressed and the current field is empty, focus on the previous field
            e.preventDefault();
            this.inputRefs[index - 1].current.focus();
        }
    };


    handleloginSubmit = async (e) => {
        e.preventDefault(); 
        this.setState({loading: true}); 
        //var deviceid = '';
        var url = '';
        var postdata = '';
        var email_option = this.state.emailOption;
        var return_code = this.state.clientState;   
        if(return_code) {
            var value = decodeBase64 (return_code);
            // deviceid = value.deviceid;   
            email_option = value.email_option;     
        }
        console.log(email_option);
        var loginmethod = this.state.loginMethod;      
        const pageurl = new URL(window.location.href);  
        const domainName = pageurl.protocol + '//' + pageurl.hostname;
        try {
            if (loginmethod === 'sms') {
                postdata = JSON.stringify({
                    mobile: this.state.userName,
                });
                url =  api_url.API_URL + 'sms_login';
            }
            if (loginmethod === 'email') {
                postdata = JSON.stringify({
                    email: this.state.userName,
                    method: email_option,
                    refer_url: domainName,
                    redirect_uri: this.state.linkUrl,
                    return_code: this.state.clientState
                });
                url =  api_url.API_URL + 'email-verification';
            }
            // console.log(url);
            // console.log(postdata);

            let res = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'apikey': this.state.apikey,
                },
                body: postdata,
            });
            let resJson = await res.json();
            if (resJson.error === true) {
                if (resJson.data && Array.isArray(resJson.data)) {                  
                    var errorObj = [];
                    resJson.data.forEach(element => {
                        errorObj[element.field] = (errorObj[element.field]) ? errorObj[element.field] + ', ' + element.message : element.message
                    });
                    this.setState({ errorMessage: errorObj, successMessage:'',loading: false });
                } 
                this.setState({ errorMessage: resJson.data.message, loading: false , successMessage:''});
            
            }
            if (resJson.success === true) {
                let msg = resJson.data.message;
                //let tel = this.formatPhoneNumber(this.state.userName);
                //let email = this.formatEmail(this.state.userName);
                // if (loginmethod === 'sms') {
                //     //msg = 'We have sent a verification code to your phone. **'+ tel;
                //     msg = msg;
                // }
                if(loginmethod === 'email') {
                    if(email_option === 'url') {
                        this.setState({ allhide: true}); 
                    } else {
                        this.setState({ allhide: false});       
                    }                                                
                }
               
                this.setState({ successMessage: msg, errorMessage: "", flag: "true", loading: false });

            }


        } catch (err) {
            this.setState({ loading: false });
            console.log(err);

        }
    }

    handlecodeSubmit = async (e) => {
        this.setState({ loading: true });
        e.preventDefault();
        var loginmethod = this.state.loginMethod;
        var return_code = this.state.clientState;         
        var deviceid = '';
        var email_option = '';
        if(return_code) {
            var value = decodeBase64 (return_code);
            deviceid = value.deviceid;   
            email_option = value.email_option;     
        }
        var apiurl = '';
        var val = '';
        var code = this.state.codes.join('');
        // console.log(this.state.codes.join(''));
        try {
            if (loginmethod === 'sms') {
                val = JSON.stringify({
                    mobile: this.state.userName,
                    code: code,
                    return_code: this.state.clientState
                });
                apiurl =  api_url.API_URL + 'sms_login/activated';
            }
            if (loginmethod === 'email') {
                val = JSON.stringify({
                    email: this.state.userName,
                    method: email_option,
                    code: code,
                    return_code: this.state.clientState
                });
                apiurl =  api_url.API_URL + 'otp-email';
            }

            let res = await fetch(apiurl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'device_id': deviceid

                },
                body: val,
            });
            let resData = await res.json();
            if (resData.error === true) {
                this.setState({ successMessage: "", loading: false });
                this.setState({ clickCount: this.state.clickCount + 1 });
                console.log(this.state.clickCount);
                if (this.state.clickCount < 3) {
                    if(resData.data.device_limit) {
                        this.setState({ errorMessage: resData.data.message});
                    } else {
                        this.setState({ errorMessage: resData.data.message + " " + (3 - this.state.clickCount) + ((this.state.clickCount < 2) ? " attempts left" : " attempt left" )});
                    }                   
                } 
                if (this.state.clickCount > 2) {
                    this.setState({ errorMessage: resData.data.message + " You have exceeded the maximum number of verification attempts." });
                }

            }
            if (resData.status === true) {
                var returnparam = '';
                if (resData.data.token) {
                    returnparam = '&token=' + resData.data.token;
                }
                if (resData.data.user) {
                    returnparam = '&user=' + resData.data.user;
                }
                this.setState({ successMessage: resData.data.message, errorMessage: "", loading: false, hide: true });

                if (this.state.linkUrl) {
                    let customfield = (this.state.clientState) ? '&return_code=' + this.state.clientState : '';
                    setTimeout(() => {
                        window.location.href = this.state.linkUrl + '?status=true' + returnparam + customfield + '&pdb=true';
                    }, 3000);
                }
            }


        } catch (err) {
            this.setState({ loading: false });
            console.log(err);

        }

    }

    getapikeyfromClientID = async (e) => {
        this.setState({loading: true});
        try {
            const response = await fetch( api_url.API_URL + 'companies/info', {
                // mode:  'no-cors',
                method: 'GET',
                headers: {
                    'client_id': getParams("client_id"),
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                this.setState({ error: true, loading : false});
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.success === true) {
                this.setState({ apikey: data.data.apikey, companyName: data.data.name_firma, companyLogo: data.data.logo_image});
                //console.log(data);
                if (getParams("user")) {
                    this.handleuserverification(data.data.apikey);
                } else {
                    this.setState({loading: false}); 
                }
            }

        } catch (err) {
            this.setState({ loading: false });
            console.log(err);

        }

    }

    socialVerification = async (servicename) => {

        this.setState({ loading: true });
        const pageurl = new URL(window.location.href);
        const domainName = pageurl.protocol + '//' + pageurl.hostname;
        // console.log(this.state.apikey);
        var urlLink =  api_url.API_URL + 'social_login';
        var val = JSON.stringify({
            refer_url: domainName,
            session_url: this.state.linkUrl,
            return_code: this.state.clientState

        });
        let res = await fetch(urlLink, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'apikey': this.state.apikey,
                'socialname': servicename

            },
            body: val,
        });
        let resCallback = await res.json();
        //console.log(resCallback.data.url);
        if (resCallback.error === false) {
            if (resCallback.data && Array.isArray(resCallback.data)) {
                var errorObj = [];
                resCallback.data.forEach(element => {
                    errorObj[element.field] = (errorObj[element.field]) ? errorObj[element.field] + ', ' + element.message : element.message
                });
                this.setState({ errorMessage: errorObj, loading: false });
            } else {
                this.setState({ errorMessage: resCallback.data, loading: false });
            }
            // console.log('here');
            // this.setState({ successMessage: "", loading:false, errorMessage: resCallback.data });
        }
        if (resCallback.success === true) {
            this.setState({ loading: true, successMessage: "Redirecting...", errorMessage: "" });
            //this.setState({loading: false,successMessage: "Redirecting..." ,  errorMessage: "",});
            setTimeout(() => {
                window.location.href = resCallback.data.url;
            }, 3000);
        }

    }

    getQueryParams = () => {
        const queryString = window.location.search.slice(1);
        const params = {};
        queryString.split('&').forEach(param => {
            const [key, value] = param.split('=');
            if (key) {
                params[decodeURIComponent(key)] = decodeURIComponent(value || '');
            }
        });
        return params;
    };

    handleuserverification = async (apikey) => {
        this.setState({loading: true});
        var loginmethod =  this.state.loginMethod ;
        var return_code = this.state.clientState;      
        var url = '';
        var postdata = '';
        var deviceid = '';
        var email_option = 'url';
        if(return_code) {
            var value = decodeBase64 (return_code);
            email_option = value.email_option;
            deviceid = value.deviceid;        
        }
        console.log(return_code);  
        const pageurl = new URL(window.location.href);
        const fullurl = pageurl.searchParams;
        const domainName = pageurl.protocol + '//' + pageurl.hostname;
        try {
            if (loginmethod === 'sms') {
                postdata = JSON.stringify({
                    mobile: this.state.userName,
                    return_code: return_code
                });
                url =  api_url.API_URL + 'sms_login';
            }
            if (loginmethod === 'email') {
                postdata = JSON.stringify({
                    email: this.state.userName,
                    method: email_option,
                    refer_url: domainName,
                    redirect_uri: this.state.linkUrl,
                    return_code: return_code
                });
                url =  api_url.API_URL + 'email-verification';
            }
            // console.log(url);
            // console.log(postdata);

            let res = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'apikey': apikey,
                    'device_id':deviceid
                },
                body: postdata,
            });
            let resJson = await res.json();
            if (resJson.error === true) {
                if (resJson.data && Array.isArray(resJson.data)) {
                    var errorObj = [];
                    resJson.data.forEach(element => {
                        errorObj[element.field] = (errorObj[element.field]) ? errorObj[element.field] + ', ' + element.message : element.message
                    });
                    this.setState({ errorMessage: errorObj, loading: false });
                } else {
                    this.setState({ errorMessage: resJson.data.message, loading: false });
                }
                           }
            if (resJson.success === true) {
                let msg = resJson.data.message;                
                if(this.state.loginMethod === 'email') {
                    if(email_option === 'url') {
                        this.setState({ allhide: true}); 
                    } else {
                        this.setState({ allhide: false});       
                    }                                                
                }
                this.setState({ successMessage: msg, errorMessage: "", flag: "true", loading: false });
                if(resJson.data.return_code) {
                    this.setState({ clientState: resJson.data.return_code}); 
                }
                if(this.state.userName) {
                    fullurl.delete('user');                   
                    const newUrl = pageurl.pathname + (fullurl.toString() ? `?${fullurl.toString()}` : "");
                    window.history.replaceState(null, "", newUrl);
                }

            }


        } catch (err) {
            this.setState({ loading: false });
            console.log(err);

        }
    }

    componentDidMount = async () => {
        const searchParams = this.getQueryParams();
        if (Object.entries(searchParams).length > 0) {
            this.setState({ checkParam: true });
        }    

        if (searchParams && searchParams.client_id) {
            this.setState({ clientId: searchParams.client_id });
            if (searchParams.user) {
                this.setState({ userName: searchParams.user });
            }
            if (searchParams.login_method) {
                this.setState({ loginMethod: searchParams.login_method });
            }
            if (searchParams.return_code) {
                this.setState({ clientState: searchParams.return_code });
            }
            if (searchParams.redirect_uri) {
                this.setState({ linkUrl: searchParams.redirect_uri });
            }
            this.getapikeyfromClientID();

        }

        if (searchParams && searchParams.code) {
            const paramcode = searchParams.code;
            //console.log(this.checkIsBase64(paramcode));
            if(checkIsBase64(paramcode)) {                 
                const decodeparam = decodeBase64(paramcode);
                const return_code = decodeparam.return_code;
                if (paramcode) {
                    fetch( api_url.API_URL + 'email/' + paramcode, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'return_code': return_code, 
                        },
                    })               
                    .then((response) => response.json())
                    .then((resJson) => {
                        var returnparam = '';
                        console.log(resJson)
                        if (resJson.status === true) {
                            if (resJson.data.token) {
                                //const decodeData = jwtDecode(resJson.data.token);
                                returnparam = '&token=' + resJson.data.token + '&pdb=true';
                                this.setState({ successMessage: resJson.data.message, errorMessage: "", flag: "true", apiCalled: true });
                                if (resJson.data.redirect_uri) {
                                    //var customfield = (resJson.data.return_code) ? '&return_code=' + resJson.data.return_code : '';
                                    setTimeout(() => {
                                        window.location.href = resJson.data.redirect_uri + '?status=true' + returnparam ;
                                    }, 3000);
                                }
                                
                            }

                            if (resJson.data.user) {
                                returnparam = '&user=' + resJson.data.user + '&pdb=true';
                                this.setState({ successMessage: resJson.data.message, errorMessage: "", flag: "true", apiCalled: true });
                                if (resJson.data.redirect_uri) {
                                    var customfield = (resJson.data.return_code) ? '&return_code=' + resJson.data.return_code : '';
                                    setTimeout(() => {
                                        window.location.href = resJson.data.redirect_uri + '?status=true' + returnparam + customfield;
                                    }, 3000);
                                } 
                            }

                        } else {
                            this.setState({ successMessage: "", errorMessage: resJson.data.message, flag: "true", apiCalled: true });
                            if (resJson.data.redirect_uri) {
                                setTimeout(() => {
                                    window.location.href = resJson.data.redirect_uri + '?status=false&user=' + resJson.data.user + '&pdb=true';
                                }, 3000);
                            }
                        }
                        
                    })
                    .catch((err) => {
                        this.setState({ successMessage: "", errorMessage: err, flag: "true", apiCalled: true });
                        // console.log(err);
                    });
                }  
            } else { 
                if(paramcode.includes('...')) {
                    this.setState({ successMessage: "", errorMessage: 'Please use the full link address.', flag: "true", apiCalled: true })
                } else {
                    this.setState({ successMessage: "", errorMessage: 'Invalid link.', flag: "true", apiCalled: true });         

                }               
            }  

        }


    } 

    render() {
        const {checkParam, clientId, apiCalled, successMessage, loading, linkUrl, error, companyName, companyLogo, flag, loginMethod, clickCount, hide, allhide, userName, emailOption, errorMessage, codes, apikey } = this.state;

        // var gap = loginMethod === 'sms' ? 'gap' : '';
        // const numInputs = loginMethod === 'sms' ? 4 : 4;

        return (
            <div className='container'>
                {

                    (!checkParam) ?
                        <Homelink />
                        : null
                }
                {
                    (checkParam) ?
                        <div className='row'>
                            <div className='tlh_form_wrap'>
                                <div id="container-scroller" className={(!clientId && !apikey) ? 'pt-5 pb-5' : 'add_border pt-5 pb-5'}>
                                    <div className='logo_wrap d-flex justify-content-center' >
                                        {
                                            companyLogo ?
                                                <img src={companyLogo} width={225} className="tlh-logo" alt="logo" /> :
                                                null
                                        }
                                    </div>

                                    {
                                        (apiCalled) ?
                                            <div>
                                                {
                                                    successMessage ? <p className='alert alert-success text-center'>{successMessage}</p>
                                                        : (errorMessage && !Array.isArray(errorMessage)) ? <p className='alert alert-warning text-center'>{errorMessage}</p>
                                                            : null
                                                }
                                            </div> : null
                                    }

                                    {
                                        (clientId && apikey) ?
                                            <div className="verification_form">
                                                {/* <p>Parameters: {this.getParams('id')}</p>  */}
                                                {
                                                    loading === true ?
                                                        <div className="loading_wrap">
                                                            <img src={loadingImg} className="loading_icon" alt="loading img" />
                                                            <span>Loading..</span>
                                                        </div>
                                                        : null
                                                }
                                                <div className="container">
                                                    <div className="content_wrap">
                                                        {
                                                            (flag === '') ?
                                                                <h6 className="text-center pt-5 pb-2">CUSTOMER VERIFICATION</h6> :
                                                                <h6 className="text-center pt-5 pb-2">WE VERIFY YOU</h6>
                                                        }

                                                        {
                                                            successMessage ? <p className='alert alert-success text-center'>{successMessage}</p>
                                                                : (errorMessage && !Array.isArray(errorMessage)) ? <p className='alert alert-warning text-center'>{errorMessage}</p>
                                                                    : null
                                                        }
                                                        { flag === '' ? (
                                                            <>
                                                            {loginMethod === "sms" && (
                                                                <SmsLogin 
                                                                    userName={userName}
                                                                    errorMessage={errorMessage}
                                                                    onChange={this.changeuserName}
                                                                    onSubmit={this.handleloginSubmit}
                                                                />
                                                            )}

                                                            {loginMethod === "email" && (
                                                            <>
                                                                <form onSubmit={this.handleloginSubmit}>
                                                                    <EmailLogin 
                                                                        userName={userName}
                                                                        errorMessage={errorMessage}
                                                                        onChange={this.changeuserName}
                                                                        emailOption = {emailOption}
                                                                        onValueChange = {this.onValueChange}
                                                                        apikey ={apikey}
                                                                    />
                                                                    <div className="text-center pt-3">
                                                                        <button type="submit" className="btn btn-primary login-btn">Login</button>
                                                                        {apikey === "tc54m8auqgn7j3llo" && 
                                                                        <SocialLogin 
                                                                            onClick={this.socialVerification}
                                                                        />}
                                                                    </div>
                                                                </form>
                                                            </> 
                                                            )}
                                                            </>
                                                        ) : (
                                                            clickCount < 4 && !hide && !allhide ? (
                                                                <CodeVerification 
                                                                    codes = {codes}
                                                                    onClick={this.handleloginSubmit}
                                                                    onSubmit={this.handlecodeSubmit}
                                                                    onChange={this.handleInputChange}
                                                                    onPaste={this.handlePaste}
                                                                    onKeyDown={this.handleKeyDown}
                                                                    inputRefs={this.inputRefs}

                                                                />
                                                            ) : (
                                                                <>
                                                                <div className="text-center trouble_wrap">
                                                                    <p>
                                                                        Haven’t received an email? <Link to="#" onClick={this.handleloginSubmit}>Get a new email</Link>.                                                                       
                                                                    </p>
                                                                </div>
                                                                <div className="text-center trouble_wrap pt-3">
                                                                    <p>
                                                                        Having trouble with the email/link? <a href="mailto:contact@theloginhub.eu">Contact Us</a>
                                                                    </p>
                                                                </div>
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                {
                                                    (linkUrl && !hide) ?
                                                        <div className="text-center pt-5">
                                                            <Link to={linkUrl+'?action=cancel'}>Cancel</Link>
                                                        </div> : null
                                                }

                                            </div>
                                            :
                                            (error && !apikey) ?
                                                <div className="mt-3 alert alert-danger text-center">Company not found!!</div>
                                                : null
                                    }
                                    {
                                        companyName ?
                                            <p className="text-center pt-3 company_name">{companyName}</p>
                                            : null
                                    }

                                </div>
                            </div>

                        </div>
                        : null
                }

            </div>


        )
    }
}
export default LoginForm;